import { createRouter, createWebHashHistory } from 'vue-router';

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      component: () => import('../pages/foundation/foundation'),
    },
    //查找
    {
      path: '/find',
      component: () => import('../pages/find/index'),
    },

    // 基金会
    {
      path: '/foundation',
      component: () => import('../pages/foundation/foundation'),
    },

     // 捐赠详情
     {
      path: '/foundationDetails',
      component: () => import('../pages/foundation/foundationDetails'),
    },

    // 捐赠
    {
      path: '/donate',
      component: () => import('../pages/donate/donate'),
    },

     // 捐赠列表
    {
      path: '/donate/donateList',
      component: () => import('../pages/donate/donateList'),
    },

    // 证书
    {
      path: '/certificate',
      component: () => import('../pages/donate/certificate'),
    },

    // 捐赠详情
    {
      path: '/donateDetails',
      component: () => import('../pages/donate/donateDetails'),
    },

    // 申领
    {
      path: '/materialsApply',
      component: () => import('../pages/materialsApply/materialsApply'),
    },

    // 申领物资详情
    {
      path: '/applyDetails',
      component: () => import('../pages/materialsApply/details'),
    },

    // 申领填写信息
    {
      path: '/applyMessage',
      component: () => import('../pages/materialsApply/applyMessage'),
    },

    // 城市选择
    {
      path: '/citySelect',
      component: () => import('../pages/citySelect/index'),
    },

    // 申领成功 领取信息查看页
    {
      path: '/findApplyMsg',
      component: () => import('../pages/find/findApplyMsg'),
    },

    // 关于我们
    {
      path: '/aboutMe',
      component: () => import('../pages/aboutMe/index'),
    },
    
    //关于我们详情
    {
      path: '/aboutMe/aboutMeDetails',
      component: () => import('../pages/aboutMe/aboutMeDetails'),
    },
    
     // 微心愿
     {
      path: '/wish',
      component: () => import('../pages/wish/index'),
    },
  ],
});

export default router;
